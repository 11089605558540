import { RefData } from 'components';
import { secureRefSplitApi } from './ref-api';

export type accountingCodesResponse = {
  PRINCIPAL_AMOUNT: RefData[];
  CHARTERER_AMOUNT: RefData[];
  OWNER_AMOUNT: RefData[];
};

type accountingCodesDTO = Partial<accountingCodesResponse>;

const buyerAccountingCodeApi = secureRefSplitApi.injectEndpoints({
  endpoints: (build) => ({
    fetchBuyerAccountingCodes: build.query<
      accountingCodesResponse,
      { buyerId: string; departmentId: string }
    >({
      query: ({ buyerId, departmentId }) => ({
        url: `/buyer/${buyerId}/department/${departmentId}/accounting-codes`,
        method: 'GET'
      }),
      transformResponse: (
        response: accountingCodesDTO
      ): accountingCodesResponse => ({
        PRINCIPAL_AMOUNT: response.PRINCIPAL_AMOUNT ?? [],
        CHARTERER_AMOUNT: response.CHARTERER_AMOUNT ?? [],
        OWNER_AMOUNT: response.OWNER_AMOUNT ?? []
      })
    })
  }),
  overrideExisting: false
});

export const {
  useFetchBuyerAccountingCodesQuery,
  useLazyFetchBuyerAccountingCodesQuery
} = buyerAccountingCodeApi;
