import { RefData } from '../../types/RefData';
import { refSplitApi, secureRefSplitApi } from './ref-api';

const countriesApi = refSplitApi.injectEndpoints({
  endpoints: (build) => ({
    fetchCountries: build.query<RefData[], void>({
      query: () => ({ url: '/countries', method: 'GET' })
    }),
    filterCountries: build.query<RefData[], string>({
      query: (countryName) => ({
        url: `/filter/countries?filter=${countryName}`,
        method: 'GET'
      })
    })
  }),

  overrideExisting: false
});

const securedCountriesApi = secureRefSplitApi.injectEndpoints({
  endpoints: (build) => ({
    fetchCountry: build.query<RefData, string | number>({
      query: (countryId) => ({
        url: `/countries/${countryId}`,
        method: 'GET'
      })
    }),
    fetchCountryPorts: build.query<
      RefData[],
      { countryId: string | number; filter: string | undefined }
    >({
      query: ({ countryId, filter }) => ({
        url: `/country/${countryId}/filter/ports?filter=${filter}`,
        method: 'GET'
      })
    })
  }),
  overrideExisting: false
});

export const {
  useFetchCountriesQuery,
  useLazyFetchCountriesQuery,
  useLazyFilterCountriesQuery,
  useFilterCountriesQuery
} = countriesApi;

export const {
  useFetchCountryPortsQuery,
  useFetchCountryQuery,
  useLazyFetchCountryQuery
} = securedCountriesApi;
