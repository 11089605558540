import { ExpenseEstimationResponse } from '../../types/ExpenseEstimationResponse';
import { portAnalysisApi } from './portAnalysisServiceApi';

const BASE_URL = '/secured/expense-estimation';

const abstractEstimationApi = portAnalysisApi.injectEndpoints({
  endpoints: (build) => ({
    deleteEstimation: build.mutation<boolean, number>({
      query: (estimationId) => ({
        url: `${BASE_URL}/${estimationId}`,
        method: 'delete'
      }),
      invalidatesTags: (res, _error, arg) => [
        'PORT_ANALYSIS_EXPENSE_ESTIMATIONS',
        'PORT_ANALYSIS_EXPENSES',
        'PORT_ANALYSIS_ESTIMATION_HISTORY'
      ]
    }),
    publishEstimation: build.mutation<ExpenseEstimationResponse, number>({
      query: (estimationId) => ({
        url: `${BASE_URL}/${estimationId}/publish`,
        method: 'patch'
      }),
      invalidatesTags: (res, _error, arg) => [
        'PORT_ANALYSIS_ESTIMATION_HISTORY',
        'PORT_ANALYSIS_EXPENSE_ESTIMATIONS'
      ]
    }),
    schedulePublishEstimation: build.mutation<
      ExpenseEstimationResponse,
      [number, Date]
    >({
      query: ([estimationId, scheduleDate]) => ({
        url: `${BASE_URL}/${estimationId}/schedule_publish`,
        method: 'patch',
        params: {
          schedule_date: scheduleDate
        }
      }),
      invalidatesTags: (res, _error, arg) => [
        'PORT_ANALYSIS_ESTIMATION_HISTORY',
        'PORT_ANALYSIS_EXPENSE_ESTIMATIONS'
      ]
    })
  }),
  overrideExisting: false
});

export const {
  useDeleteEstimationMutation,
  usePublishEstimationMutation,
  useSchedulePublishEstimationMutation
} = abstractEstimationApi;
