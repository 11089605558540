import { axiosBaseQuery, createAxiosInstance } from '@client';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { FormulaDocumentTranslatorFeedbackForm } from './models';
import { AxiosHeaders, AxiosHeaderValue } from 'axios';

export const expenseEvaluationDocumentTranslationApi = createApi({
  baseQuery: axiosBaseQuery({
    customAxiosInstance: createAxiosInstance({
      responseType: 'blob'
    })
  }),
  reducerPath: 'expense-evaluation-document-translation-api',
  endpoints: (build) => ({
    fetchTranslatedPortExpenseDocument: build.query<
      { document: Blob; requestId: AxiosHeaderValue },
      {
        evaluationId: string;
        expenseEvaluationId: number;
        fileItemId: number;
      }
    >({
      query: ({ evaluationId, expenseEvaluationId, fileItemId }) => ({
        url: `/secured/download/evaluation/${evaluationId}/expense/${expenseEvaluationId}/attachment/${fileItemId}/translate`,
        method: 'GET'
      }),
      transformResponse: async (
        response: Promise<Blob>,
        meta: { headers: AxiosHeaders }
      ) => {
        return { document: await response, requestId: meta.headers.get('id') };
      }
    }),
    rateExpenseEvaluationFormulaDocumentTranslation: build.mutation<
      void,
      FormulaDocumentTranslatorFeedbackForm
    >({
      query: (data) => ({
        url: `/secured/formula/document/translator/feedback`,
        method: 'POST',
        data
      })
    })
  })
});

export const {
  useLazyFetchTranslatedPortExpenseDocumentQuery,
  useRateExpenseEvaluationFormulaDocumentTranslationMutation
} = expenseEvaluationDocumentTranslationApi;
