import { createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import {
  api,
  EventTemplate,
  GroupedEventTemplates,
  Sof,
  SofEvent,
  SofEventAction,
  SofEventNote,
  SofRemarkSuggestion
} from './generated';

import type {
  DefinitionsFromApi,
  OverrideResultType,
  TagTypesFromApi
} from '@reduxjs/toolkit/dist/query/endpointDefinitions';

type Definitions = DefinitionsFromApi<typeof api>;
type TagTypes = TagTypesFromApi<typeof api>;

type UpdatedDefinitions = Definitions & {
  getSofStatuses: OverrideResultType<
    Definitions['getSofStatuses'],
    EntityState<Sof>
  >;
  getSofs: OverrideResultType<Definitions['getSofs'], EntityState<Sof>>;
  getEventTemplates: OverrideResultType<
    Definitions['getEventTemplates'],
    EntityState<GroupedEventTemplates>
  >;
  getSuggestedEventTemplates: OverrideResultType<
    Definitions['getSuggestedEventTemplates'],
    EntityState<EventTemplate>
  >;
  getEventComments: OverrideResultType<
    Definitions['getEventComments'],
    Array<SofEventNote>
  >;
  getActivityTimeline: OverrideResultType<
    Definitions['getActivityTimeline'],
    Array<SofEventAction>
  >;
  getSuggestedRemarks: OverrideResultType<
    Definitions['getSuggestedRemarks'],
    Array<SofRemarkSuggestion>
  >;
};

export const statementOfFactsAdapter = createEntityAdapter<Sof>({
  selectId: (sof) => sof.portCallId
});

export const groupedEventsAdapter = createEntityAdapter<GroupedEventTemplates>({
  selectId: (ge) => ge.group,
  sortComparer: (a, b) =>
    a.group.toLowerCase().localeCompare(b.group.toLowerCase())
});

export const statementOfFactsEventsAdapter = createEntityAdapter<SofEvent>({
  selectId: (e) => e.id,
  sortComparer: (a, b) => {
    return Number(a.id) - Number(b.id);
  }
});

export const { selectById: selectSofEventById } =
  statementOfFactsEventsAdapter.getSelectors();

export const { selectAll: selectAllGroupedEvents } =
  groupedEventsAdapter.getSelectors();

export const eventsTemplateAdapter = createEntityAdapter<EventTemplate>({
  selectId: (e) => e.code,
  sortComparer: (a, b) =>
    a.code.toLowerCase().localeCompare(b.code.toLowerCase())
});

export const { selectAll: selectAllSmartEvents } =
  eventsTemplateAdapter.getSelectors();

export const enhancedSofApi = api.enhanceEndpoints<
  TagTypes,
  UpdatedDefinitions
>({
  endpoints: {
    getSofStatuses: {
      // https://redux-toolkit.js.org/rtk-query/api/createApi#providestags
      providesTags: (result, _error, _arg) =>
        result
          ? [...result.ids.map((id) => ({ type: 'SOF_STATUS' as const, id }))]
          : ['SOF_STATUS'],
      transformResponse: ({ getSofsForPortCallIds }) => {
        return statementOfFactsAdapter.setAll(
          statementOfFactsAdapter.getInitialState(),
          getSofsForPortCallIds
        );
      }
    },
    getSofs: {
      serializeQueryArgs: ({ queryArgs }) =>
        `sof_${(queryArgs.ids instanceof Array
          ? queryArgs.ids
          : [queryArgs.ids]
        ).join()}`,
      providesTags: (result, _error, _arg) =>
        result
          ? [...result.ids.map((id) => ({ type: 'SOF' as const, id }))]
          : ['SOF'],
      transformResponse: ({ getSofsForPortCallIds }) => {
        return statementOfFactsAdapter.setAll(
          statementOfFactsAdapter.getInitialState(),
          getSofsForPortCallIds
        );
      }
    },
    getSuggestedEventTemplates: {
      providesTags: ['SMART_EVENTS'],
      transformResponse: ({ getSuggestedEventTemplates }) => {
        return eventsTemplateAdapter.setAll(
          eventsTemplateAdapter.getInitialState(),
          getSuggestedEventTemplates
        );
      }
    },
    getEventTemplates: {
      transformResponse: ({
        getEventTemplates
      }: {
        getEventTemplates: GroupedEventTemplates[];
      }) => {
        const departGroup = getEventTemplates.find(
          (et) => et.group.toLowerCase() === 'depart'
        );

        if (
          departGroup &&
          departGroup.eventTemplates.findIndex(
            (et) => et.code === 'SIGNATURE'
          ) === -1
        ) {
          departGroup.eventTemplates.push({
            code: 'SIGNATURE',
            description: 'Digital Signature',
            mandatory: true
          });
        }

        return groupedEventsAdapter.setAll(
          groupedEventsAdapter.getInitialState(),
          getEventTemplates
        );
      },
      keepUnusedDataFor: 60 * 60 // these events should never change
    },
    getEventComments: {
      providesTags: (result, _error, _arg) =>
        result
          ? [
              {
                type: 'SOF_EVENT_COMMENTS' as const,
                id: `${_arg.portCallId}_${_arg.eventId}`
              }
            ]
          : ['SOF_EVENT_COMMENTS'],
      transformResponse: ({ getEventComments }) => getEventComments
    },
    addEvent: {
      invalidatesTags: (result, _error, _arg) =>
        result
          ? [
              'SMART_EVENTS',
              { type: 'SOF' as const, id: result.addEvent.portCallId },
              {
                type: 'SOF_ACTIVITY_TIMELINE' as const,
                id: result.addEvent.portCallId
              }
            ]
          : ['SMART_EVENTS', 'SOF']
    },
    editEvent: {
      invalidatesTags: (
        result,
        _error,
        { editEventRequestDto: { portCallId } }
      ) =>
        result
          ? [
              'SMART_EVENTS',
              { type: 'SOF' as const, id: portCallId },
              {
                type: 'SOF_ACTIVITY_TIMELINE' as const,
                id: portCallId
              }
            ]
          : ['SMART_EVENTS', 'SOF']
    },
    deleteEvent: {
      invalidatesTags: (result, _error, { portCallId }) =>
        result
          ? [
              'SMART_EVENTS',
              { type: 'SOF' as const, id: portCallId },
              {
                type: 'SOF_ACTIVITY_TIMELINE' as const,
                id: portCallId
              }
            ]
          : ['SMART_EVENTS', 'SOF', 'SOF_ACTIVITY_TIMELINE']
    },
    addEventComment: {
      invalidatesTags: (
        result,
        _error,
        { addEventCommentRequestDto: { portCallId, sofEventId } }
      ) =>
        result
          ? [
              { type: 'SOF' as const, id: portCallId },
              {
                type: 'SOF_EVENT_COMMENTS' as const,
                id: `${portCallId}_${sofEventId}`
              },
              {
                type: 'SOF_ACTIVITY_TIMELINE' as const,
                id: portCallId
              }
            ]
          : ['SOF', 'SOF_EVENT_COMMENTS', 'SOF_ACTIVITY_TIMELINE']
    },
    addSofComment: {
      invalidatesTags: (
        result,
        _error,
        { addSofCommentRequestDto: { portCallId } }
      ) =>
        result
          ? [
              'SOF_COMMENTS',
              {
                type: 'SOF_COMMENTS' as const,
                id: portCallId
              },
              {
                type: 'SOF_ACTIVITY_TIMELINE' as const,
                id: portCallId
              },
              { type: 'SOF' as const, id: portCallId }
            ]
          : ['SOF_COMMENTS', 'SOF']
    },
    addParameters: {
      invalidatesTags: (
        result,
        _error,
        { addParametersRequestDto: { portCallId } }
      ) => (result ? [{ type: 'SOF' as const, id: portCallId }] : ['SOF'])
    },
    getActivityTimeline: {
      transformResponse: ({ getActivityTimeline }) => {
        return getActivityTimeline;
      },
      providesTags: (result, _error, { portCallId }) =>
        result
          ? [
              {
                type: 'SOF_ACTIVITY_TIMELINE' as const,
                id: portCallId
              }
            ]
          : ['SOF_ACTIVITY_TIMELINE']
    },
    addRemarksOnEvent: {
      invalidatesTags: (
        result,
        _error,
        { addEventRemarksRequestDto: { portCallId } }
      ) => (result ? [{ type: 'SOF' as const, id: portCallId }] : ['SOF'])
    },
    editEventRemarks: {
      invalidatesTags: (
        result,
        _error,
        { editEventRemarksRequestDto: { portCallId } }
      ) => (result ? [{ type: 'SOF' as const, id: portCallId }] : ['SOF'])
    },
    deleteEventRemark: {
      invalidatesTags: (result, _error, arg) =>
        result ? [{ type: 'SOF' as const, id: arg.portCallId }] : ['SOF']
    },
    addGeneralRemarks: {
      invalidatesTags: (
        result,
        _error,
        { addGeneralRemarksRequestDto: { portCallId } }
      ) =>
        result
          ? [
              { type: 'SOF' as const, id: portCallId },
              { type: 'SOF_SUGGESTED_GENERAL_REMARKS' as const, id: portCallId }
            ]
          : ['SOF', 'SOF_SUGGESTED_GENERAL_REMARKS']
    },
    editSofRemark: {
      invalidatesTags: (
        result,
        _error,
        { editSofRemarkRequestDto: { portCallId } }
      ) =>
        result
          ? [
              { type: 'SOF' as const, id: portCallId },
              { type: 'SOF_SUGGESTED_GENERAL_REMARKS' as const, id: portCallId }
            ]
          : ['SOF', 'SOF_SUGGESTED_GENERAL_REMARKS']
    },
    deleteRemark: {
      invalidatesTags: (result, _error, { portCallId }) =>
        result
          ? [
              { type: 'SOF' as const, id: portCallId },
              { type: 'SOF_SUGGESTED_GENERAL_REMARKS' as const, id: portCallId }
            ]
          : ['SOF', 'SOF_SUGGESTED_GENERAL_REMARKS']
    },
    getSuggestedRemarks: {
      transformResponse: ({ getSuggestedRemarks }) => {
        return getSuggestedRemarks;
      },
      providesTags: (result, _error, { portCallId }) =>
        result
          ? [{ type: 'SOF_SUGGESTED_GENERAL_REMARKS' as const, id: portCallId }]
          : ['SOF_SUGGESTED_GENERAL_REMARKS']
    },
    setRemarksNeeded: {
      invalidatesTags: (result, _error, { portCallId }) =>
        result ? [{ type: 'SOF' as const, id: portCallId }] : ['SOF']
    }
  }
});

export const invalidateSof = (portCallId: string) =>
  enhancedSofApi.util.invalidateTags([{ type: 'SOF', id: portCallId }]);

export const {
  useGetSofStatusesQuery,
  useGetSofsQuery,
  useGetEventTemplatesQuery,
  useGetSuggestedEventTemplatesQuery,
  useAddEventMutation,
  useAddParametersMutation,
  useEditEventMutation,
  useDeleteEventMutation,
  useGetEventCommentsQuery,
  useLazyGetEventCommentsQuery,
  useAddEventCommentMutation,
  useAddSofCommentMutation,
  useGetActivityTimelineQuery,
  useLazyGetActivityTimelineQuery,
  useAddRemarksOnEventMutation,
  useEditEventRemarksMutation,
  useDeleteEventRemarkMutation,
  useAddGeneralRemarksMutation,
  useLazyGetEventTemplatesQuery,
  useEditSofRemarkMutation,
  useDeleteRemarkMutation,
  useGetSuggestedRemarksQuery,
  useLazyGetSuggestedRemarksQuery,
  useSetRemarksNeededMutation
} = enhancedSofApi;
