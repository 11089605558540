import { createAxiosInstance } from '@client';
import {
  BaseQueryApi,
  BaseQueryFn,
  createApi
} from '@reduxjs/toolkit/dist/query/react';
import { AxiosRequestConfig, isAxiosError } from 'axios';
import { Messages } from 'src/app/sitemap/_common/messages/messages';

const axiosBaseQuery: BaseQueryFn<
  {
    url: string;
    method: AxiosRequestConfig['method'];
    data?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
    headers?: AxiosRequestConfig['headers'];
  },
  unknown,
  { status: number | undefined; data: any }
> = async ({ url, method, data, params, headers }, api: BaseQueryApi) => {
  // don't import RootState here! cyclic deps
  const state = api.getState() as {
    session: { authentication: { token: string } };
  };
  try {
    const axiosInstance = createAxiosInstance({
      token: state.session.authentication.token
    });

    const result = await axiosInstance({
      url,
      method,
      data,
      params,
      headers
    });

    return { data: result.data };
  } catch (err) {
    if (isAxiosError(err)) {
      const message = err.response?.data?.message;
      return {
        error: {
          status: err.response?.status,
          data: { message: Messages.parse(message) }
        }
      };
    } else {
      return {
        data: Messages.list().common.error.general
      };
    }
  }
};

export const mainRestApi = createApi({
  baseQuery: axiosBaseQuery,
  reducerPath: 'rest-api',
  endpoints: () => ({}),
  tagTypes: [
    'ADMIN_NOTES',
    'BUYER_SETTINGS_PROFILE',
    'BUYER_SETTINGS_USER_PROFILE',
    'BUYER_SETTINGS_BILLING_COMPANY',
    'POSITION_LIST_PORT_CALL',
    'EXPENSE_GROUPS_HUSBANDRY',
    'REQUEST_CASE'
  ]
});
