import { RefData } from '../../types/RefData';
import { secureRefSplitApi } from './ref-api';

const portsApi = secureRefSplitApi.injectEndpoints({
  endpoints: (build) => ({
    fetchPortRef: build.query<RefData, { portId: string }>({
      query: ({ portId }) => ({
        url: `/port/${portId}`,
        method: 'GET'
      })
    }),
    filterPorts: build.query<RefData, string>({
      query: (filter) => ({
        url: `/filter/ports`,
        method: 'GET',
        params: { filter }
      })
    }),
    filterActivePorts: build.query<RefData[], string>({
      query: (filter) => ({
        url: `/filter/active-ports`,
        method: 'GET',
        params: { filter }
      })
    })
  })
});

export const {
  useFetchPortRefQuery,
  useLazyFetchPortRefQuery,
  useFilterPortsQuery,
  useLazyFilterPortsQuery,
  useFilterActivePortsQuery,
  useLazyFilterActivePortsQuery
} = portsApi;
