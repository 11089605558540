import { ExpenseEstimationResponse } from '../../types/ExpenseEstimationResponse';
import { portAnalysisApi } from './portAnalysisServiceApi';

const BASE_URL = '/secured/expense-estimation-formula';

const estimationApi = portAnalysisApi.injectEndpoints({
  endpoints: (build) => ({
    getEstimation: build.query<ExpenseEstimationResponse, number>({
      query: (estimationId) => ({
        url: BASE_URL,
        method: 'get',
        params: { expenseEstimationId: estimationId }
      }),
      providesTags: (res, _error, arg) =>
        res
          ? [
              {
                type: 'PORT_ANALYSIS_EXPENSE_ESTIMATIONS' as const,
                id: res.expenseId
              }
            ]
          : ['PORT_ANALYSIS_EXPENSE_ESTIMATION']
    }),
    archiveEstimation: build.mutation<ExpenseEstimationResponse, number>({
      query: (estimationId) => ({
        url: `${BASE_URL}/${estimationId}/archive`,
        method: 'patch'
      }),
      invalidatesTags: (res, _error, arg) =>
        res
          ? [
              {
                type: 'PORT_ANALYSIS_EXPENSE_ESTIMATIONS' as const,
                id: res.expenseId
              },
              ...res.locations
                .map((x) => x.id)
                .map((id) => ({
                  type: 'PORT_ANALYSIS_EXPENSES' as const,
                  id
                })),
              'PORT_ANALYSIS_ESTIMATION_HISTORY'
            ]
          : [
              'PORT_ANALYSIS_EXPENSE_ESTIMATIONS',
              'PORT_ANALYSIS_ESTIMATION_HISTORY',
              'PORT_ANALYSIS_EXPENSES'
            ]
    }),
    create: build.mutation<ExpenseEstimationResponse, any>({
      query: (formulaDto) => ({
        url: `${BASE_URL}`,
        method: 'POST',
        data: formulaDto
      }),
      invalidatesTags: (res, _error, arg) => [
        'PORT_ANALYSIS_ESTIMATION_HISTORY',
        'PORT_ANALYSIS_EXPENSE_ESTIMATIONS'
      ]
    }),
    update: build.mutation<ExpenseEstimationResponse, [number, any]>({
      query: ([estimationId, formulaForm]) => ({
        url: `${BASE_URL}/${estimationId}`,
        method: 'PUT',
        data: formulaForm
      }),
      invalidatesTags: (res, _error, arg) => [
        'PORT_ANALYSIS_ESTIMATION_HISTORY',
        'PORT_ANALYSIS_EXPENSE_ESTIMATIONS'
      ]
    }),
    getEstimationHistory: build.query<ExpenseEstimationResponse[], number>({
      query: (expenseId) => ({
        method: 'GET',
        url: `${BASE_URL}/${expenseId}/history`
      }),
      providesTags: (res, _error, arg) =>
        res
          ? [
              {
                type: 'PORT_ANALYSIS_ESTIMATION_HISTORY' as const,
                id: arg
              }
            ]
          : ['PORT_ANALYSIS_ESTIMATION_HISTORY']
    })
  }),
  overrideExisting: false
});

export const {
  useGetEstimationQuery,
  useLazyGetEstimationQuery,
  useGetEstimationHistoryQuery,
  useLazyGetEstimationHistoryQuery,
  useArchiveEstimationMutation,
  useCreateMutation,
  useUpdateMutation
} = estimationApi;
